import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import api from "../api";
import "../index.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "../components/Spinner";
import BottomNavbar from "../components/BottomNavbar";
import { useSearchParams } from "react-router-dom";

const Students = () => {
  const [loading, setLoading] = useState(true);
  const [searchParam, setSearchParam] = useSearchParams();
  const [students, setStudents] = useState([]);
  const getStudents = async () => {
    setLoading(true);
    const batch = searchParam.get("batch");
    api
      .get(`/studentList.php?batch=${batch}`)
      .then((res) => {
        console.log(res.data);
        setStudents(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Error fetching students");
      });
  };

  useEffect(() => {
    getStudents();
  }, []);

  if (loading)
    return (
      <div>
        <Spinner />
      </div>
    );
  return (
    <div className="bg-white relative min-h-screen h-full dark:bg-gray-900 flex flex-col items-center justify-center">
      <Navbar />
      <BottomNavbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />

      <div className="mt-6 lg:mt-16 mb-16 lg:mx-20 relative mx-auto flex flex-col items-center">
        <p className="text-2xl font-bold">
          Batch {searchParam.get("batch")} Students
        </p>
        <div className="mt-8 flex flex-row flex-wrap gap-4 mx-auto items-center justify-evenly">
          {/* Map over students */}
          {students.map((student) => (
            <div class="flex flex-col items-center justify-evenly w-72 bg-white border border-gray-200 rounded-lg dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
              <img
                class="object-cover rounded-t-lg  w-1/2 h-48 md:w-36 md:h-48 md:rounded-none md:rounded-l-lg mt-2"
                src={student.pic}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <h5 class="mb-2 text-lg font-medium tracking-tight text-gray-900 dark:text-white">
                  {student.name}
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Students;
