/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import api from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalState } from "../components/UserContext";
import BottomNavbar from "../components/BottomNavbar";
import { MdOutlineMailOutline } from "react-icons/md";
import { BiChevronRight } from "react-icons/bi";

const People = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const [jwt, setJwt] = useGlobalState("jwt");

  const commitee = [
    {
      name: "Mr. Abu Ahmed Fredaus",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/cse_ferdaus.jpg",
      designation: "Co-ordinator",

      email: "ferdaus@du.ac.bd",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/1775",
    },
    {
      name: "Prof. Dr. Md. Abdur Razzaque",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/image_1768_new.jpg",
      designation: "Member",

      email: "razzaque@du.ac.bd",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/1768",
    },
    {
      name: "Prof. Dr. Saifuddin Md. Tareeq",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/image_1767.jpg",
      designation: "Member",

      email: "smtareeq@du.ac.bd",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/1767",
    },
    {
      name: "Mr. Md. Shahnewaz Islam",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/image_2856.jpg",
      designation: "Assistant Registrar",

      email: "shahnewaz@cse.du.ac.bd",
      profile: "https://www.du.ac.bd/body/officers_staffs/CSE",
    },
  ];

  const instructors = [
    {
      name: "Dr. Md. Abdur Razzaque",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/image_1768_new.jpg",
      designation: "Professor",
      institution: "CSE, DU",
      email: "razzaque@du.ac.bd",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/1768",
    },
    {
      name: "Dr. Mosarrat Jahan",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/cse_mosarratjahan.jpg",
      designation: "Associate Professor",
      institution: "CSE, DU",

      email: "mosarratjahan@yahoo.com",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/1777",
    },
    {
      name: "Dr. Sarker Tanveer Ahmed",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/image_2585.jpg",
      designation: "Associate Professor",
      institution: "CSE, DU",
      email: "rumee@cse.du.ac.bd",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/2585",
    },

    {
      name: "Dr. Md. Rezaul Karim",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/cse_rkarim.jpg",
      designation: "Professor",
      institution: "CSE, DU",
      email: "rkarim@du.ac.bd",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/1763",
    },
    {
      name: "Dr. Mosaddek Hossain Kamal",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/image_1764.JPG",
      designation: " Professor",
      institution: "CSE, DU",
      email: "tushar@du.ac.bd",
      profile: "https://www.du.ac.bd/body/faculty_details/CSE/1764",
    },

    {
      name: "Mr. Md. Faisal Hossain",
      photo: "https://pmics.cse.du.ac.bd/api/img/MdFaisalHossain.jpg",
      designation: "CISO",
      institution: "MBL",
      email: "",
      profile: "",
    },

    {
      name: "Dr. Md. Shariful Islam",
      photo: "https://pmics.cse.du.ac.bd/api/img/shariful_islam.jpeg",
      designation: "Professor",
      institution: "IIT, DU",
      email: "",
      profile: "http://www.iit.du.ac.bd/about_iit/individual_teacher/19",
    },

    {
      name: "Barrister Nabil Ahmed Khan",
      photo: "https://pmics.cse.du.ac.bd/api/img/nabil_ahmed_khan.jpeg",
      designation: "Advocate",
      institution: "Supreme Court of Bangladesh",
      email: "",
      profile: "",
    },
  ];

  const co_instrucors = [
    {
      name: "Abu Ahmed Ferdouse",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/cse_ferdaus.jpg",
      designation: "Associate Professor",
      institution: "CSE, DU",
      email: "ferdaus@cse.du.ac.bd",
      profile: "https://du.ac.bd/body/faculty_details/CSE/1775",
    },
    {
      name: "Md. Tanvir Alam",
      photo:
        "https://ssl.du.ac.bd/fontView/assets/faculty_image/image_61083_new.JPG",
      designation: "Lecturer",
      institution: "CSE, DU",
      email: "tanvir@cse.du.ac.bd",
      profile: "https://du.ac.bd/body/faculty_details/CSE/61083",
    },
    {
      name: "Mr. Mohammad Shahadat Hossain",
      photo: "https://pmics.cse.du.ac.bd/api/img/MohammadShahadatHossain.jpg",
      designation: "Head of Information Security and Principal IT Architect",
      institution: "Grameenphone Ltd.",
      email: "",
      profile: "",
    },

    {
      name: "Mr. Tawhidur Rahman",
      photo: "https://pmics.cse.du.ac.bd/api/img/TawhidurRahman.jpg",
      designation: "Senior Technical Specialist (Digital Security & Diplomacy)",
      institution: "BGD E-GOV CIRT",
      email: "",
      profile: "",
    },

    {
      name: "Mr. Rafiqul Islam",
      photo: "https://pmics.cse.du.ac.bd/api/img/rafiqul_islam.jpeg",
      designation:
        "Vice President and Head of Information Security (acting) and Technology Governance",
      institution: "",
      email: "",
      profile: "",
    },
  ];

  const tas = [
    {
      name: "Md Mehedi Hasan",
      photo: "https://pmics.cse.du.ac.bd/api/img/md_mehedi_hasan.jpeg",
      designation: "Cyber Security Engineer",
      institution: "Trustaira Ltd.",
      email: "",
      profile: "",
    },
    {
      name: "Shahebul Karim",
      photo: "https://pmics.cse.du.ac.bd/api/img/ShahebulKarim.jpeg",
      designation: "Digital Forensic Analyst",
      institution: "BGD E-GOV CIRT",
      email: "",
      profile: "",
    },
    {
      name: "Md. Shakhawat Hossain",
      photo: "https://pmics.cse.du.ac.bd/api/img/shakhawat_hossain.jpeg",
      designation: "Information Security Professional",
      institution: "Mercantile Bank PLC",
      email: "",
      profile: "",
    },
    {
      name: "Mr. Md. Samiul Islam",
      photo: "https://pmics.cse.du.ac.bd/api/img/samiul_islam.jpeg",
      designation: "Incident Associate",
      institution: "BGD E-GOV CIRT",
      email: "",
      profile: "",
    },
  ];

  return (
    <div className="bg-white min-h-screen h-full dark:bg-gray-900 flex flex-col">
      <Navbar />
      <BottomNavbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      <div
        className="mt-6 lg:mt-16 mb-16 mx-10 relative overflow-x-auto"
        id="instructors"
      >
        {/* Instructor Panel */}
        <div className="mb-16 w-3/4 mx-auto">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white ">
            Instructors
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {instructors.map((instructor, i) => {
              return (
                <div
                  key={i}
                  className="rounded-sm shadow-md pb-4 w-64 mx-4 mt-8"
                >
                  <div
                    style={{
                      background: `url(${instructor.photo})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "300px",
                    }}
                  ></div>
                  {/* <img
                      src={instructor.photo}
                      alt={instructor.name}
                      className="img-fluid mx-auto w-3/4 h-3/4"
                    /> */}
                  <div className="text-center font-medium text-sm lg:text-xl mt-4 h-12">
                    {instructor.name}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.designation}
                  </div>

                  <div className="text-center text-neutral-500 my-2">
                    {instructor.institution}
                  </div>

                  {instructor.email.length > 0 ? (
                    <div className=" text-center flex flex-row items-center justify-center">
                      <MdOutlineMailOutline className="mr-2" />{" "}
                      {instructor.email}
                    </div>
                  ) : (
                    <></>
                  )}

                  <a
                    href={instructor.profile}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        {/* Co-instructor */}
        <div className="mb-16 w-3/4 mx-auto" id="tas">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white ">
            Co-Instructors
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {co_instrucors.map((co_instrucor, i) => {
              return (
                <div
                  key={i}
                  className="rounded-sm shadow-md pb-4 w-64 mx-4 mt-8"
                >
                  <div
                    style={{
                      background: `url(${co_instrucor.photo})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "300px",
                    }}
                  ></div>
                  <div className="text-center font-medium text-md lg:text-xl mt-4">
                    {co_instrucor.name}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {co_instrucor.designation}
                  </div>

                  <div className="text-center text-neutral-500 my-2">
                    {co_instrucor.institution}
                  </div>

                  {co_instrucor.email.length > 0 ? (
                    <div className=" text-center flex flex-row items-center justify-center">
                      <MdOutlineMailOutline className="mr-2" />{" "}
                      {co_instrucor.email}
                    </div>
                  ) : (
                    <></>
                  )}

                  <a
                    href={co_instrucor.profile}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        {/* Teaching Assistant */}
        <div className="mb-16 w-3/4 mx-auto" id="tas">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white ">
            Teaching Assistants
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {tas.map((instructor, i) => {
              return (
                <div
                  key={i}
                  className="rounded-sm shadow-md pb-4 w-64 mx-4 mt-8"
                >
                  <div
                    style={{
                      background: `url(${instructor.photo})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: "300px",
                    }}
                  ></div>
                  <div className="text-center font-medium text-md lg:text-xl mt-4">
                    {instructor.name}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.designation}
                  </div>

                  <div className="text-center text-neutral-500 my-2">
                    {instructor.institution}
                  </div>

                  {instructor.email.length > 0 ? (
                    <div className=" text-center flex flex-row items-center justify-center">
                      <MdOutlineMailOutline className="mr-2" />{" "}
                      {instructor.email}
                    </div>
                  ) : (
                    <></>
                  )}

                  <a
                    href={instructor.profile}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        {/* Commitee Panel */}
        <div id="committee">
          <div className="text-xl mb-16 md:text-3xl text-center dark-text-white">
            Co-ordination Commitee
          </div>
          <div className=" flex flex-row items-center justify-evenly flex-wrap">
            {commitee.map((instructor, i) => {
              return (
                <div key={i} className="rounded-sm shadow-md pb-4">
                  <img
                    src={instructor.photo}
                    alt={instructor.name}
                    className="img-fluid mx-auto"
                  />
                  <div className="text-center font-medium text-md lg:text-xl mt-4">
                    {instructor.name}
                  </div>
                  <div className="text-center text-neutral-500 mt-2">
                    {instructor.designation}
                  </div>
                  <div className=" text-center flex flex-row items-center justify-center">
                    <MdOutlineMailOutline className="mr-2" /> {instructor.email}
                  </div>
                  <a
                    href={instructor.profile}
                    target="blank"
                    className="text-center cursor-pointer bg-blue-500 hover:bg-blue-600 text-white w-3/4 mx-auto rounded-md shadow-sm py-1 my-2 flex items-center justify-center"
                  >
                    View Profile
                    <BiChevronRight className="text-white text-2xl" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default People;
